<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>{{ $t('keyAccount') }}</h3>
            </v-col>
            <v-col 
                cols="2" 
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on"
                            :to="{ name: 'keyAccountForm', params: { id: 0 } }"
                        >
                            {{ $t('novo') }} {{ $t('keyAccount') }}
                        </v-btn>
                    </template>
                    <span>{{ $t('novo') }} {{ $t('keyAccount') }}</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-form @submit.prevent="getRegisters">
                    <v-text-field
                        v-model="filter.fastSearch"
                        :label="$t('pesquisaRapida')"
                        append-outer-icon="mdi mdi-magnify"
                        outlined
                        single-line
                        @click="options.page=1;"
                        @click:append-outer="getRegisters"
                    ></v-text-field>
                </v-form>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredSearch"
                    :options.sync="options"
                    :loading="loading"
                    :items-per-page="10"
                    :no-data-text="$t('nenhumRegistroEncontrado')"
                    :footer-props="{
                        'items-per-page-text':$t('registrosPorPagina'),
                        'items-per-page-all-text':$t('todos'),
                        pageText: '{0}-{1} de {2}'
                    }"
                >                 
                    <template v-slot:item.statusDescricao="{ item }">
                        <v-chip
                            :color="getStatusColor(item.status)"
                            outlined
                            dark
                        >
                            {{ item.statusDescricao }}
                        </v-chip>
                    </template>

                    <template v-slot:item.action="{item}">
                        <ActionList 
                            :id="item.id" 
                            :link="'keyAccountForm'"
                            :showButtons="{
                                edit: item.status == 1,
                                cancel: item.status === 1 ? true : false,
                                active: item.status === 0 ? true : false,
                                clonar: item.status == 1,
                            }"
                            @confirmCancel="confirmCancel"
                            @confirmActive="confirmActive"
                            @confirmClone="confirmClone"
                        />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionList from "@/components/Layout/ActionList";
    import ActionDialog from "@/components/Layout/ActionDialog";

    export default ({

        components: {
            ActionList: ActionList,
            ActionDialog: ActionDialog
        },

        mixins: [Helpers],

        data: vue => ({

            loading: false,
            statusId: 0,

            filter: {
                fastSearch: ''
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            headers: [
                { text: "ID", value: "id", sortable: true, size: "5%" },
                { text: vue.$t('criadoEm'), value: "dataCadastroFormatted", sortable: true },
                { text: vue.$t('criadoPor'), value: "nomeUsuarioCadastro", sortable: true },
                { text: vue.$t('cliente'), value: "nomeCliente", sortable: true },
                { text: vue.$t('tipoCliente'), value: "tipoClienteDescricao", sortable: true },
                { text: vue.$t('status'), value: "statusDescricao", sortable: true },
                { text: vue.$t('acoes'), value: "action", sortable: false, align: "center" }
            ],

            options: { rowsPerPage: 10, page: 1 },

            listKeyAccount: [],
        }),

        computed: {
            filteredSearch: function() {
                return this.listKeyAccount.filter((cotacaoFilter) => {
                    
                    const filter = this.filter.fastSearch.toLowerCase();

                    const dataCadastroFormatted = cotacaoFilter.dataCadastroFormatted.toLowerCase().match(filter)
                    const nomeUsuarioCadastro = cotacaoFilter.nomeUsuarioCadastro.toLowerCase().match(filter)
                    const nomeCliente = cotacaoFilter.nomeCliente.toLowerCase().match(filter)
                    const statusDescricao = cotacaoFilter.statusDescricao.toLowerCase().match(filter)
                    const id = cotacaoFilter.id.toString().match(filter);

                    if(dataCadastroFormatted != null) { return dataCadastroFormatted; }
                    if(nomeCliente != null) { return nomeCliente; }
                    if(nomeUsuarioCadastro != null) { return nomeUsuarioCadastro; }
                    if(statusDescricao != null) { return statusDescricao; }
                    else { return id; }
                });
            }
        },

        methods: {

            getStatusColor (status) {

                let color = "";

                switch (status) {
                    
                    //ATIVO
                    case 1: 
                        color = 'var(--color__main)';
                        break;

                    //INATIVAR 
                    case 0:
                        color = 'var(--color__status_inactive)';
                        break;
                
                    default:
                        break;
                }

                return color
            },

            async getRegisters() {

                this.listKeyAccount = await this.$store.dispatch("keyAccountModule/List");
            },

            confirmCancel(id) {

                this.statusId = 0;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaInativacaoKeyAccount'),
                    methodConfirm: this.cancel,
                    params: id
                };
            },

            async cancel(id) {

                const statusId = this.statusId;

                this.showLoading();

                const result = await this.$store.dispatch("keyAccountModule/AlterarStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },

            confirmActive(id) {

                this.statusId = 1;

                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaAtivacaoKeyAccount'),
                    methodConfirm: this.active,
                    params: id
                };
            },

            async active(id) {

                this.showLoading();
                let statusId = this.statusId;

                let result = await this.$store.dispatch("keyAccountModule/AlterarStatus", { id, statusId });

                if (result.success) {
                    this.showToast("success", this.$t('sucesso'), result.message);
                }
                else {
                    this.showToast("error", "Erro!", result.message);
                }

                this.getRegisters();

                this.hideLoading();
            },
            
            confirmClone(id) {
                this.dialog = {
                    show: true,
                    headerText: this.$t('confirmacao'),
                    bodyText: this.$t('perguntaCloneKeyAccount'),
                    methodConfirm: this.clonar,
                    params: id
                };
            },

            async clonar(id) {

                this.showLoading();

                this.$router.push({ name: "keyAccountForm", params: { id, clonar: true } });

                this.hideLoading();
            },
        },

        async created() {
            this.showLoading();

            await this.getRegisters();

            this.hideLoading();
        }
    })
</script>
